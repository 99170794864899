import React from 'react'
import Layout from 'components/Layout'
import TermsOfUseNets from 'components/TermsOfUseNets'

const EmbeddedTermsOfUseNetsPage = () => {
  return (
    <Layout onlyPage>
      <TermsOfUseNets />
    </Layout>
  )
}

export default EmbeddedTermsOfUseNetsPage
